


















































































































































































































































































import { View } from '@/app_code/services/meta/view'
import { Component, Mixins } from 'vue-property-decorator'
import CalloutCard from '../components/cards/CalloutCard.vue'
import FeatureList from '../components/cards/FeatureList.vue'
import PricingCard from '../components/cards/PricingCard.vue'
import PricingCardCycler from '../components/cycler/PricingCardCycler.vue'
import Dots from '../components/design/Dots.vue'
import SideBySide from '../components/layout/SideBySide.vue'
import Topic from '../components/Topic.vue'
import CustomPackageBuilder from '@/components/CustomPackageBuilder.vue'
import ImageCard from '../components/cards/ImageCard.vue'
import CustomQuollPackageBuilder from '../components/CustomQuollPackageBuilder.vue'

@Component({
  components: {
    PricingCard,
    SideBySide,
    Topic,
    CalloutCard,
    Dots,
    PricingCardCycler,
    CustomPackageBuilder,
    ImageCard,
    FeatureList,
    CustomQuollPackageBuilder
  }
})
export default class Pricing extends Mixins(View) {
  private showCustomPackage = false

  private showQuollCustomPackage = false
}
